import truncate from "lodash-es/truncate";
import type { CellInput, RowInput, Styles, UserOptions } from "jspdf-autotable";
import type {
  IEvent,
  IEventAthleteMetric,
  IEventAthleteStats,
  IEventGameStats,
} from "~/types";
import { createReport } from "~/videos/helpers/pdf";
import type { ItemStatValue } from "~/videos/helpers/get-stat-value";
import getStatValue from "~/videos/helpers/get-stat-value";

export async function generateStatsReportByEventId(
  eventId: string,
  options: { locale?: string; t: (key: string) => string },
) {
  const { t } = options;
  const event: IEvent = await getCachedEventById(eventId);
  const teamStatsGroups = await getTeamStatsReportTableGroupsByEventId(
    eventId,
    {
      locale: options?.locale,
    },
  )
    .then((res) => {
      return res.filter(g => g.tables.length);
    })
    .catch(() => []);
  const athleteStartGroupIndex = teamStatsGroups.reduce((acc, group) => {
    if (group.tables.length) {
      acc += 1;
    }
    return acc;
  }, 1);
  const athleteStatsGroups = await getAthleteStatsReportTableGroupsByEventId(
    eventId,
    {
      startGroupIndex: athleteStartGroupIndex,
      locale: options?.locale,
    },
  )
    .then(res => res.filter(g => g.tables.length))
    .catch(() => []);
  const formatDate = (date: Date | number, format: string) =>
    useDateFormat(new Date(date || ""), format, {
      locales: options.locale || "en",
    }).value;

  if (
    areAllTablesEmpty(teamStatsGroups)
    && areAllTablesEmpty(athleteStatsGroups)
  ) {
    throw new Error("There are no stats available for export!");
  }

  const matchName = `${event.game_info.team1_name} vs ${event.game_info.team2_name}`;
  const matchDate = formatDate(event.start_date, "MMMM DD, YYYY");

  return createReport({
    fileName: `${t("labels.full_report")} ${matchDate} - ${matchName}`,
    title: t("labels.full_report"),
    description: `${event.game_info.team1_name} — ${event.game_info.team2_name}`,
    date: formatDate(event.start_date, "MMMM DD, YYYY hh:mm A"),
    coverBackground: `/images/pdf/covers/${event.sport_type}.png`,
    sections: [
      { heading: t("navigation.label_videos_stats"), groups: teamStatsGroups },
      {
        heading: t("navigation.label_videos_players"),
        groups: athleteStatsGroups,
      },
    ],
  });
}

async function getTeamStatsReportTableGroupsByEventId(
  eventId: string,
  options?: {
    startGroupIndex?: number;
    accessToken?: string;
    locale?: string;
  },
): Promise<
    {
      label: string;
      teams?: { name?: string; logo_url?: string }[];
      tables: {
        head?: RowInput[];
        body?: RowInput[];
        additionalOptions?: Partial<UserOptions>;
      }[];
    }[]
  > {
  const requestOptions = {
    accessToken: options?.accessToken,
    locale: options?.locale,
  };
  const statsOptions = await getCachedTeamStatsTypeOptionsByEventId(
    eventId,
    requestOptions,
  );
  const promises = statsOptions.map((s) => {
    return getCachedEventGameStatsById(eventId, {
      accessToken: options?.accessToken,
      locale: options?.locale,
      query: {
        format: "sheet" as const,
        sheetId: s.value,
      },
    }).catch(() => null);
  });
  const data = (await Promise.all(promises)).filter(
    r => r !== null,
  ) as IEventGameStats[];
  const startGroupIndex = options?.startGroupIndex || 1;
  const groups = [];

  for (const [index, option] of statsOptions.entries()) {
    if (!data[index]) continue;

    const categorizedStats = createMapArrayFrom(data[index].stats, "category");

    const group = {
      label: `${startGroupIndex + index}. ${option.label}`,
      teams: [
        {
          name: data[index].team_home.name,
          logo_url: data[index].team_home.logo,
        },
        {
          name: data[index].team_away.name,
          logo_url: data[index].team_away.logo,
        },
      ],
      tables: [] as {
        head?: RowInput[];
        body?: RowInput[];
        additionalOptions?: Partial<UserOptions>;
      }[],
    };

    for (const [category, stats] of categorizedStats.entries()) {
      const head = [[{ content: category, colSpan: 3 }]];
      const body: RowInput[] = [];

      for (const s of stats) {
        body.push([
          await resolveCellValue(s.homeTeamValue.displayValue, {
            cellWidth: 150,
            fontStyle: getFontStyleForTeamStatValue(
              s.homeTeamValue,
              s.awayTeamValue,
            ),
          }),
          {
            content: s.name,
            styles: {
              cellWidth: 255,
            },
          },
          await resolveCellValue(s.awayTeamValue.displayValue, {
            cellWidth: 150,
            fontStyle: getFontStyleForTeamStatValue(
              s.awayTeamValue,
              s.homeTeamValue,
            ),
          }),
        ]);
      }

      group.tables.push({
        head,
        body,
        additionalOptions: {
          rowPageBreak: "avoid",
        },
      });
    }

    groups.push(group);
  }

  return groups;
}

function getFontStyleForTeamStatValue(
  stat: ItemStatValue,
  comparedStat: ItemStatValue,
): "normal" | "bold" {
  if (stat.type === "progress" && comparedStat.type === "progress") {
    return stat.numericValue > comparedStat.numericValue ? "bold" : "normal";
  }

  return "normal";
}

async function getAthleteStatsReportTableGroupsByEventId(
  eventId: string,
  options?: {
    startGroupIndex?: number;
    accessToken?: string;
    locale?: string;
  },
): Promise<
    {
      label: string;
      teams?: { name?: string; logo_url?: string }[];
      tables: {
        head?: RowInput[];
        body?: RowInput[];
        additionalOptions?: Partial<UserOptions>;
      }[];
    }[]
  > {
  const requestOptions = {
    accessToken: options?.accessToken,
    locale: options?.locale,
  };
  const statsOptions = await getCachedAthleteStatsTypeOptionsByEventId(
    eventId,
    requestOptions,
  );
  const promises = statsOptions.map((s) => {
    return getCachedEventAthletesStatsById(eventId, {
      accessToken: options?.accessToken,
      locale: options?.locale,
      query: {
        format: "sheet" as const,
        sheetId: s.value,
      },
    });
  });
  const data = (await Promise.all(promises)).filter(
    r => r !== null,
  ) as IEventAthleteStats[];
  const startGroupIndex = options?.startGroupIndex || 1;
  const groups: {
    label: string;
    teams?: { name?: string; logo_url?: string }[];
    tables: {
      head?: RowInput[];
      body?: RowInput[];
    }[];
  }[] = [];

  for (const [index, option] of statsOptions.entries()) {
    if (!data[index]) continue;

    const team_home = data[index].team_home;
    const team_away = data[index].team_away;
    const stats = data[index].stats;

    const groupHome = {
      label: `${startGroupIndex + index}.1 ${option.label}`,
      teams: [{ name: team_home.name, logo_url: team_home.logo }],
      tables: await getStatsTablesByTeam("home", stats),
    };
    const groupAway = {
      label: `${startGroupIndex + index}.2 ${option.label}`,
      teams: [{ name: team_away.name, logo_url: team_away.logo }],
      tables: await getStatsTablesByTeam("away", stats),
    };

    groups.push(groupHome);
    groups.push(groupAway);
  }

  return groups;
}

async function getStatsTablesByTeam(
  team: "home" | "away",
  stats: IEventAthleteStats["stats"],
): Promise<
    {
      head?: RowInput[];
      body?: RowInput[];
      additionalOptions?: Partial<UserOptions>;
    }[]
  > {
  if (
    !stats
    || !stats.length
    || !stats[0]
    || !stats[0].metrics.length
    || !stats[0].metrics[0]
  )
    return Promise.resolve([]);

  const hasChartsData = stats[0].metrics[0].value.text.includes("<svg");
  const teamStats = stats.filter(s => s.athlete.team === team);

  if (!teamStats.length) return Promise.resolve([]);

  if (hasChartsData) return [await getAthleteStatsChartsTableByTeam(teamStats)];
  return [await getAthleteStatsMetricTableByTeam(teamStats)];
}

async function getAthleteStatsMetricTableByTeam(
  stats: IEventAthleteStats["stats"],
): Promise<{
    head?: RowInput[];
    body?: RowInput[];
    additionalOptions?: Partial<UserOptions>;
  }> {
  const athleteNamesCols: RowInput = stats.map((stat, index) => {
    const cell: CellInput = {
      content: truncate(stat.athlete.name || "-", { length: 21 }),
      styles: {
        font: "RedHatDisplay-Medium",
        fontStyle: "normal",
        fontSize: 8,
        cellPadding: {
          top: 8,
          bottom: 8,
          left: 10,
          right: 10,
        },
        minCellHeight: 98,
        minCellWidth: 30,
        lineColor: "#DFDFDF",
        lineWidth: {
          left: 1,
          right: stats[index + 1] ? 1 : 0,
        },
      },
      // @ts-expect-error Custom text rotation
      rotate: true,
    };
    return cell;
  });

  athleteNamesCols.unshift({
    content: "",
    styles: {
      minCellWidth: 64,
    },
  });

  const athleteNumbersCols: RowInput = stats.map((s) => {
    const cell: CellInput = {
      content: s.athlete.number || "-",
      styles: {
        font: "RedHatDisplay-Medium",
        fontStyle: "normal",
        fillColor: "#FFFFFF",
        fontSize: 8,
        cellPadding: {
          top: 8,
          bottom: 8,
          left: 10,
          right: 10,
        },
      },
    };
    return cell;
  });

  athleteNumbersCols.unshift({
    content: "#",
    styles: {
      font: "RedHatDisplay-Medium",
      fontStyle: "normal",
      fontSize: 8,
      fillColor: "#FFFFFF",
      minCellWidth: 64,
      valign: "middle",
    },
  });

  // Create table head: athlete names as columns
  const head = [athleteNamesCols, athleteNumbersCols];
  const colsNumber = Object.keys(athleteNamesCols).length;

  // Create table body: metric names and their values for each athlete
  const body: RowInput[] = [];

  // Extracting unique categories
  const uniqueCategories = Array.from(
    new Set(stats.flatMap(s => s.metrics.map(metric => metric.category))),
  );

  // Adding rows for each category
  for (const categoryName of uniqueCategories) {
    const styles: Partial<Styles> = {
      cellWidth: "wrap",
      font: "RedHatDisplay-Bold",
      fontStyle: "bold",
      fontSize: 10,
      halign: "center",
      fillColor: "#EEEEEE",
      textColor: "#000000",
    };
    const headRow: RowInput = [
      // TODO: The content should be centered in the row
      { content: categoryName, colSpan: 1, styles },
    ];

    // This is a workaround to add empty cells to fix the row
    for (let i = 0; i < colsNumber - 1; i++) {
      headRow.push({ content: "", colSpan: 1, styles });
    }

    body.push(headRow);

    const categoryMetrics
      = stats[0]?.metrics.filter(m => m.category === categoryName) || [];

    for (const metric of categoryMetrics) {
      const metricNameCell: keyof IEventAthleteMetric = "shortName";
      const metricRow: (string | number)[] = [metric[metricNameCell]];
      for (const athlete of stats) {
        const athleteMetric = athlete.metrics.find(
          m =>
            m.category === categoryName
            && m[metricNameCell] === metric[metricNameCell],
        );

        const value = athleteMetric?.value?.text;
        const metricValue
          = value && !Number.isNaN(+value) && +value % 1 !== 0
            ? (+value).toFixed(2)
            : value;

        metricRow.push(metricValue ?? "");
      }
      body.push(getFormattedAthleteMetricRow(metricRow));
    }
  }

  return {
    head,
    body,
    additionalOptions: {
      bodyStyles: {
        fontSize: 8,
      },
      alternateRowStyles: {
        fontSize: 8,
      },
      horizontalPageBreak: true,
      horizontalPageBreakRepeat: [0],
    },
  };
}

/**
 * Returns a formatted row for the athletes stats table
 * The cell with the highest value will be highlighted with a primary color and bold font
 */
function getFormattedAthleteMetricRow(
  metricRow: (string | number)[],
): RowInput {
  const highestValue = Math.max(
    ...metricRow
      .map((m) => {
        const stat = getStatValue(m);
        return stat.type === "progress" ? stat.numericValue : 0;
      })
      .filter(Number.isFinite),
  );

  const formattedRow: RowInput = metricRow.map((value) => {
    const stat = getStatValue(value);
    const styles: Partial<Styles> = {
      fontStyle: "normal",
      font: "RedHatDisplay-Medium",
      textColor: "#1A1C1E",
    };
    if (
      stat.type === "progress"
      && stat.numericValue === highestValue
      && stat.numericValue !== 0
    ) {
      styles.fontStyle = "bold";
      styles.font = "RedHatDisplay-Bold";
      styles.textColor = "#009BC9";
    }
    return { content: stat.displayValue, styles };
  });

  return formattedRow;
}

async function getAthleteStatsChartsTableByTeam(
  stats: IEventAthleteStats["stats"],
): Promise<{
    head?: RowInput[];
    body?: RowInput[];
    additionalOptions?: Partial<UserOptions>;
  }> {
  // Extracting unique categories
  // const uniqueCategories = Array.from(new Set(stats.flatMap((s) => s.metrics.map((m) => m.category || "-"))));
  const metricCols = stats[0]?.metrics.map(metric => metric.name) || [];

  // Extracting headers
  const head: RowInput[] = [
    // ["", "", ...uniqueCategories],
    ["#", "Athlete Name", ...metricCols],
  ];
  const body: RowInput[] = [];

  for (const stat of stats) {
    const row: CellInput[] = [stat.athlete.number, stat.athlete.name || "-"];
    for (const metric of stat.metrics) {
      const value = await resolveCellValue(metric.value.text, {
        cellWidth: 154,
        cellPaddingX: 16,
        cellPaddingY: 16,
      });
      row.push(value);
    }

    body.push(row);
  }

  return {
    head,
    body,
    additionalOptions: {
      alternateRowStyles: {
        fillColor: "#FFFFFF",
      },
      bodyStyles: {
        fillColor: "#FFFFFF",
      },
      rowPageBreak: "avoid",
    },
  };
}

async function resolveCellValue(
  cellValue: string,
  options?: Partial<{
    cellPaddingX: number;
    cellPaddingY: number;
    cellWidth: number;
    fontStyle?: "normal" | "bold";
  }>,
): Promise<CellInput> {
  if (!cellValue.includes("<svg")) {
    const cellWidth = options?.cellWidth || 185;
    const cellPaddingX = options?.cellPaddingX || 0;
    return {
      content: cellValue,
      styles: {
        cellWidth: cellWidth + cellPaddingX,
        fontStyle: options?.fontStyle,
        font:
          options?.fontStyle === "bold"
            ? "RedHatDisplay-Bold"
            : "RedHatDisplay-Medium",
        textColor: options?.fontStyle === "bold" ? "#009BC9" : "#1A1C1E",
      },
    };
  }
  return convertSVGtoDataURL(cellValue, options);
}

function areAllTablesEmpty(
  data: {
    label: string;
    teams?: { name?: string; logo_url?: string }[];
    tables: {
      head?: RowInput[];
      body?: RowInput[];
      additionalOptions?: Partial<UserOptions>;
    }[];
  }[],
): boolean {
  for (const entry of data) {
    for (const table of entry.tables) {
      // Check if both head and body are undefined or empty arrays
      const isTableEmpty
        = (!table.head || table.head.length === 0)
          && (!table.body || table.body.length === 0);

      if (!isTableEmpty) {
        return false; // If any table is not empty, return false
      }
    }
  }

  return true; // All tables are empty
}

function convertSVGtoDataURL(
  svgString: string,
  options?: Partial<{
    cellPaddingX: number;
    cellPaddingY: number;
    cellWidth: number;
  }>,
): Promise<CellInput> {
  const svgContainer = document.createElement("div");
  svgContainer.innerHTML = svgString;
  const svg = svgContainer.children[0] as SVGElement;
  const styleTag = svgContainer.children[1] as HTMLStyleElement;

  applyStylesFromStyleTag(styleTag, svg);

  const svgData = new XMLSerializer().serializeToString(svg);

  return new Promise((resolve, reject) => {
    // Create a Blob from the SVG string
    const blob = new Blob([svgData], { type: "image/svg+xml;charset=utf-8" });
    const url = URL.createObjectURL(blob);
    /**
     * The resulted SVG will be rendered as PNG with the following scale factor.
     * This will increase the size and the quality of the resulted image, so the text on it should be readable.
     */
    const scaleFactor = 2;

    // Load this Blob into an Image
    const img = new Image();
    img.onload = () => {
      // Once loaded, draw it to a canvas
      const canvas = document.createElement("canvas");
      canvas.width = img.width * scaleFactor;
      canvas.height = img.height * scaleFactor;
      const ctx = canvas.getContext("2d");

      if (!ctx) {
        throw new Error("Failed to get '2d' context from canvas element!");
      }

      ctx.drawImage(img, 0, 0);

      const cellWidth = options?.cellWidth || 185;
      const cellPaddingX = options?.cellPaddingX || 0;
      const cellPaddingY = options?.cellPaddingY || 16;
      // Calculate the aspect ratio of the original image
      const aspectRatio: number = img.width / img.height;
      // Calculate the new height of the cell
      const newCellHeight: number = cellWidth / aspectRatio;
      // Calculate the new width of the image based on the new height and original aspect ratio
      const newImageWidth: number = newCellHeight * aspectRatio;

      // Convert canvas to a data URL and resolve the promise
      const dataUrl = canvas.toDataURL("image/png");

      const cell: CellInput = {
        content: dataUrl,
        // @ts-expect-error storing image data
        contentImageHeight: newCellHeight,
        contentImageWidth: newImageWidth,

        styles: {
          valign: "top",
          halign: "left",
          minCellHeight: newCellHeight + cellPaddingY,
          cellWidth: cellWidth + cellPaddingX,
        },
      };

      resolve(cell);
      // Clean up for memory management
      URL.revokeObjectURL(url);
    };
    img.onerror = reject;
    img.src = url;
  });
}

function applyStylesFromStyleTag(
  styleTag: HTMLStyleElement,
  svgElement: SVGElement,
) {
  const styleText = styleTag.textContent;
  const styleParser = new DOMParser();
  const styleDoc = styleParser.parseFromString(
    `<style>${styleText}</style>`,
    "application/xml",
  );
  const styleNodes = styleDoc.getElementsByTagName("style");

  // Apply styles to the SVG element
  for (let i = 0; i < styleNodes.length; i++) {
    const rules = styleNodes[i]?.textContent?.split("}") || [];
    for (let j = 0; j < rules.length - 1; j++) {
      const rule = rules[j]?.trim();
      if (rule) {
        const [selector, style] = rule.split("{");

        if (!selector || !style) {
          return;
        }

        const elements = svgElement.querySelectorAll(selector.trim());
        elements.forEach((element) => {
          const stylePairs = style.split(";");
          stylePairs.forEach((pair) => {
            const [property, value] = pair.split(":");
            if (property && value) {
              // @ts-expect-error applying inline styles
              element.style[property.trim()] = value.trim();
            }
          });
        });
      }
    }
  }
}
